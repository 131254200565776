<template>
  <v-menu
    bottom
    left
    min-width="200"
    offset-y
    origin="top right"
    transition="scale-transition"
  >
    <template v-slot:activator="{ attrs, on }">
      <v-btn
        class="ml-2"
        min-width="0"
        text
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-account</v-icon>
        <span style="text-transform: none;">{{username}}</span>
      </v-btn>
    </template>

    <v-list
      :tile="false"
      flat
      nav
    >
      <template v-for="(p, i) in profile">
        <v-divider
          v-if="p.divider"
          :key="`divider-${i}`"
          class="mb-2 mt-2"
        />

        <AppBarItem
          v-else
          :key="`item-${i}`"
          :to="p.url"

        >
          <v-list-item-title v-text="p.title"    @click="p.method()"/>
        </AppBarItem>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
  import AppBarItem from "../../../components/app/BarItem";
  export default {
    name: 'DefaultAccount',
    components: {AppBarItem},
    mounted() {
      this.username = JSON.parse(sessionStorage.getItem("userInfo")).username
    },
    data: () => ({
      profile: [
        { title: '个人信息设置','url':'/admin/userInfo',method:()=>{} },
        { divider: true },
        { title: '修改密码','url':'/admin/updatePassword',method:()=>{} },
        { divider: true },
        { title: '登出','url':'/admin/exit'},
      ],
      username:""
    }),
  }
</script>
